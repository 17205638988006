import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';

const DropdownMenu = ({
  items,
  onItemClick,
  buttonLabel,
  listSubheaderLabel,
  icon: Icon = ExpandMore,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    if (item) {
      onItemClick(item);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="paper"
        sx={{
          p: 2,
          width: '50%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-around',
        }}
        onClick={handleClick}
      >
        <Typography variant="h5" color="text.primary">
          {buttonLabel}
        </Typography>
        <Icon color="primary" />
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ListSubheader component="div">{listSubheaderLabel}</ListSubheader>
        {items.map((item) => (
          <MenuItem
            key={item.id}
            value={item}
            sx={{ height: 50, justifyContent: 'center' }}
            onClick={() => handleClose(item)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  listSubheaderLabel: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
};

DropdownMenu.defaultProps = {
  icon: ExpandMore,
};

export default DropdownMenu;
