import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import React from 'react';
import BicolorBackgroundBox from '../boxes/BicolorBackgroundBox';
import FingoCircularProgressLoader from '../loader/FingoCircularProgressLoader';
import MutationNotification from './MutationNotification';
import { useIsMobile } from '../../hooks';

const FingoNotificationPanel = ({ loading, messageParagraph }) => {
  const isMobile = useIsMobile();

  return (
    <Stack minHeight="100%">
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '10px',
          background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main} 60%)`,
          position: 'relative',
        })}
      />
      <BicolorBackgroundBox
        paddingTop={isMobile ? 2 : 4}
        height={isMobile ? '100%' : ''}
        flexGrow="1"
        px="4%"
        sx={(theme) => ({
          background: isMobile
            ? theme.palette.text.primary
            : `linear-gradient(${theme.palette.text.primary} 50%, ${theme.palette.gray.A200} 30%)`,
        })}
      >
        <Paper sx={{ backgroundColor: 'white', justifyContent: 'center', borderRadius: 4, height: '85vh' }}>
          {loading ? <FingoCircularProgressLoader /> : (
            <MutationNotification
              messageParagraph={messageParagraph}
            />
          )}
        </Paper>
      </BicolorBackgroundBox>
    </Stack>
  );
};

FingoNotificationPanel.propTypes = {
  loading: PropTypes.bool,
  messageParagraph: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }),
};

FingoNotificationPanel.defaultProps = {
  loading: true,
  messageParagraph: {
    header: '',
    body: '',
  },
};

export default FingoNotificationPanel;
