import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const IconStackOverlay = ({ children }) => (
  <Stack
    mt={4}
    width="100%"
    height="50%"
    alignItems="center"
    justifyContent="center"
    style={{ zIndex: 5, position: 'relative' }}
  >
    <DescriptionRoundedIcon
      sx={{
        width: 40,
        height: 'auto',
        mb: 1,
        color: 'primary.main',
      }}
    />
    {children}
  </Stack>
);

IconStackOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconStackOverlay;
